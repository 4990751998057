<template>
  <div>
    <div>
      <div class="is-flex mb-3 items-center">
        <i class="is-size-5" :class="currentProcess?.icon"></i>
        <span class="ml-3 font-semibold is-size-5"> {{ currentProcess?.name }} </span>
        <div class="filters flex ml-auto items-center">
          <!-- <i class="fa fa-filter mx-2 fa-xs"  -->
          <!--   :class="[ useProcessStore().documents_filter_status != '' ||  useProcessStore().documents_filter_month != '' ? 'text-blue-300' : 'text-gray-400']"> </i> -->
          <a href="" @click.prevent="resetFilters" class="mx-2 relative">
            <img width="16" height="16"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAuklEQVR4nO2UMQrCMBSGP3QTPYJHcBBP4SB4Dx08hw4epIO3cJCeQbA3aEcVIk/+IRSpaSwomA865L3vzwuFBBItcR193xtgHCRlz1UYmTKWfcsYqBRYBPhzuZWyQWwUugDDBm8AnOVaJpg+kCu4bfB2cnJlWjED7sANmL7oT4CrHHOj2OuEp9oJe8BRPXOiGQGFNlp59bVqhZyPWGqz0quVqlmvE1ztAgVdqDTApV/k42Ke5Z8a8Mc8AHpXbRYf6WnfAAAAAElFTkSuQmCC"
              :style="{ 
                'opacity': filtersEnabled ? '.7' : '0.4', 
              }" >
              <!-- show small circle if filters are enabled --> 
              <div v-if="filtersEnabled" class="absolute bottom-0 right- right-0 w-1 h-1 bg-red-500 rounded-full">&nbsp;</div>
          </a>
          <!-- filter documents by status -->
          <label class="ml-3 font-thin flex items-center">
            <span class="mr-2">Statusi</span>
            <select class="select is-small" v-model="useProcessStore().documents_filter_status"
              @change="useProcessStore().filter_documents(currentProcess.guid)">
              <option value="">Te gjitha</option>
              <option value="1">Perfunduara</option>
              <option value="0">Aktive</option>
            </select>
          </label>
          <!-- filter by month -->
          <label class="ml-3 font-thin flex items-center">
            <span class="mr-2">Muaji</span>
            <select class="select is-small" v-model="useProcessStore().documents_filter_month"
              @change="useProcessStore().filter_documents(currentProcess.guid)">
              <option value="0">Te gjitha</option>
              <option v-for="month in useProcessStore().months" :key="month.id" :value="month.id">{{ month.name }}
              </option>
            </select>
          </label>
          <!-- filter by text --> 
          <div class="ml-3 flex items-center gap-1 p-1">
            <input type="text" class="input is-small" placeholder="Kerko..." v-model="useProcessStore().documents_filter_text"
              @keyup="useProcessStore().filter_documents(currentProcess.guid)">
            <i class="fa fa-search fa-xs mx-2 text-gray-300"></i>
          </div>
        </div>
        <span @click.prevent="create_document()"
          class="is-flex items-center ml-auto is-clickable hover:bg-blue-50 p-1 px-4 border-blue-500 border text-blue-500">
          <i class="fa fa-plus mr-1 fa-xs"></i> KRIJO
        </span>
      </div>
      <!-- <p class="is-size-5 mb-3">Dokumentet per procesin: <span class="font-semibold">{{useProcessStore().process_documents[0]?.name}}</span></p> -->
      <div class="flex flex-col table-wrapper">
        <div class="table-container overflow-auto">
          <table class="table is-bordered is-fullwidth mb-3">
            <thead style="background: #f0f0f0">
              <tr>
                <th style="text-align: center; width: 0px">#</th>
                <th>Statusi</th>
                <th>
                  <div class="flex items-center justify-between gap-2">
                    <span>
                      <div v-if="useProcessStore().process_documents[0]?.origin_type">
                        {{ getPartyName(useProcessStore().process_documents[0]?.origin_type) }}
                        <i class="fa fa-arrow-right text-gray-400 ml-1"></i>
                      </div>
                    </span>
                    <span>
                      <div v-if="useProcessStore().process_documents[0]?.destination_type">
                        <i class="fa fa-arrow-right text-gray-400 mr-1"></i>
                        {{ getPartyName(useProcessStore().process_documents[0]?.destination_type) }}
                      </div>
                    </span>
                  </div>
                </th>
                <th>Vlera</th>
                <th>Krijuar</th>
                <th>Kompletuar</th>
              </tr>
            </thead>
            <tbody>
              <tr class="is-clickable hover:bg-slate-100" @click.prevent="toRoute(document)"
                :class="{ 'bg-green-50': document.completed == 1 && !document.with_rejection, 'bg-red-50': document.with_rejection }"
                v-for="document in useProcessStore().process_documents
                  .filter(document => JSON.stringify(document).toLowerCase().includes(useProcessStore().documents_filter_text.toLowerCase()))
                  .slice((current_page - 1) * items_per_page, current_page * items_per_page)"
                :key="document.guid">
                <td style="text-align: center;">
                  {{ document.process_number }}
                </td>
                <td>
                  <span class="ml-2 text-blue-600" v-if="document.completed == 0"> {{ document.step_name }}</span>
                  <div v-else-if="document.with_rejection">
                    <div class="text-center text-red-600">
                      <i class="fa-solid fa-xmark mr-1"></i>
                    </div>
                  </div>
                  <div class="text-center text-green-600" v-else>
                    <i class="fa fa-check mr-1"></i>
                  </div>
                </td>

                <td>
                  <div class="flex items-center justify-between gap-2 h-full">
                    <div :class="{ 'text-red-600': document.origin_type === null || document.origin === null }">
                      <span v-if="document.origin_type == 1">{{ document.pos_origin_name }}</span>
                      <span v-else-if="document.origin_type == 2">{{ document.warehouse_origin_name }}</span>
                      <span v-else-if="document.origin_type == 4">{{ document.customer_origin_name }}</span>
                    </div>

                    <div :class="{ 'text-red-600': document.destination_type === null || document.destination === null }">
                      <span v-if="document.destination_type == 1">{{ document.pos_destination_name }}</span>
                      <span v-else-if="document.destination_type == 2">{{ document.warehouse_destination_name }}</span>
                      <span v-else-if="document.destination_type == 4">{{ document.customer_destination_name }}</span>
                    </div>
                  </div>
                </td>

                <td style="text-align: right">{{ numeral(document.total_price).format("0,0") }}</td>
                <td>
                  <div class="flex justify-between items-center">
                    <div class="text-xs uppercase">
                      <i class="fa fa-user mr-1 fa-xs text-gray-400"></i>
                      {{ document.user_name }}
                      <span class="block">
                        <i class="fa fa-clock mr-1 fa-xs text-gray-400"></i>
                        {{ useProcessStore().date_filter(document.created_at) }}
                      </span>
                    </div>
                    <div class="font-thin text-xs uppercase">
                      {{ months[document.month_created - 1] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="flex justify-around items-center gap-1">
                    <i v-if="document.completed == 1 && !document.with_rejection " class="fa fa-check text-green-500"></i>
                    <i v-if="document.completed == 0" class="fa fa-times text-red-500"></i>

                    <div v-if="document.completed == 1" class="text-xs uppercase">
                      <i class="fa fa-user mr-1 text-gray-400"></i>
                      {{ document.user_completed_name }}
                      <!-- show icon and date time was closed_at -->
                      <span class="block">
                        <i class="fa fa-clock mr-1 text-gray-400"></i>
                        {{ useProcessStore().date_filter(document.closed_at) }}
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination flex justify-center">
          <Dapaginator 
            :totalPages="total_pages" 
            :currentPage="current_page" 
            @changePage="change_page"></Dapaginator>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useProcessStore } from '@/stores/process'
var numeral = require("numeral");
import Dapaginator from '@/components/Dapaginator.vue'
 

function toRoute(document) {
  window.location.href = '/process/document/' + document.process_id + '/' + document.guid
}

function getPartyName(party) {
  switch (party) {
    case 1:
      return 'POS'
    case 2:
      return 'Magazine'
    case 3:
      return 'Furnitor'
    case 4:
      return 'Klient'
    default:
      return ''
  }
}

function resetFilters() {
  useProcessStore().documents_filter_status = ''
  useProcessStore().documents_filter_month = 0
  useProcessStore().documents_filter_text = ''
  useProcessStore().get_process_documents(useProcessStore().process_documents[0]?.process_id)
}

</script>
<script>
export default {
  data() {
    return {
      current_page: 1,
      items_per_page: 12,
      months: [
        "Janar",
        "Shkurt",
        "Mars",
        "Prill",
        "Maj",
        "Qershor",
        "Korrik",
        "Gusht",
        "Shtator",
        "Tetor",
        "Nentor",
        "Dhjetor",
      ]

    }
  },
  methods: {
    change_page(page) {
      this.current_page = page
    },
    create_document() {
      // Go to create route od current 
      window.location.href = '/process/' + this.$route.params.process_id + '/new'
    },
    getProcessName() {
      return useProcessStore().processes_list.find(process => process.guid == this.$route.params.process_id)?.name
    }

  },
  computed: {
    currentProcess() {
      return useProcessStore().processes_list.find(process => process.guid == this.$route.params.process_id)
    },
    total_pages() {
      return Math.ceil(useProcessStore().process_documents.length / this.items_per_page)
    },
    filtersEnabled() {
      return useProcessStore().documents_filter_status != '' || useProcessStore().documents_filter_month != '' || useProcessStore().documents_filter_text != ''
    }
  },
  watch: {
    '$route.params.process_id'(newValue, oldValue) {
      if (newValue !== oldValue) {
        // Fetch the process documents for the new process ID
        useProcessStore().get_process_documents(newValue);
      }
    },
  },
}
</script>

<style scoped>
td {
  vertical-align: middle;
}

td:first-child {
  width: 0px;
  color: #4a5568;
  font-size: 0.8rem;
}

.filters {
  margin: auto;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  padding: 0.05rem;
}

label>span {
  font-size: 0.8rem;
  color: #4a5568;
  padding-right: 0.25rem;
  border-right: 1px solid #e2e8f0;
}

.table-container {
  flex-grow: 1;
}

.pagination {
  margin-top: 10px;
}

tbody {
  flex-grow: 1;
  align-items: center;
}

.table-wrapper {
  height: calc(100vh - 140px);
}
</style>
